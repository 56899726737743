<template>
    <div>
        <!-- <van-nav-bar title="我的信息" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <!-- 内容 -->
        <div class="content">
            <!-- 身份证 -->
            <div class="block">
                <div class="title_top">
                    <div class="col_1A1A1A font_bold">基本信息</div>
                    <van-uploader :after-read="getimgidcard" v-if="!is_entry">
                        <div class="title_top_rt"></div>
                    </van-uploader>
                    <!-- <div class="title_top_rt"></div> -->
                </div>
                <!-- 没有地址也没有民族那就一定没有识别身份证 -->
                <div>
                    <div class="cell" @click="goeditname(1)">
                        <div class="left">
                            <div class="title">姓名</div>
                        </div>
                        <div class="right">
                            <div class="text pd-r" :class="is_entry?' disabled':''">{{details.name || '请输入'}}</div>
                        </div>
                    </div>
                    <div class="cell" @click="goeditname(2)">
                        <div class="left">
                            <div class="title">身份证号</div>
                        </div>
                        <div class="right">
                            <div class="text pd-r" :class="is_entry?'pd-r':''">{{details.card_num || '请输入'}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">性别</div>
                        </div>
                        <div :class="['right',is_entry ? 'pd-r' : '']" @click="is_entry || details.card_num ? '' : show_sex = true">
                            <!-- <div class="text">{{details.sex_desc}}</div> -->
                            <input :class="is_entry || details.card_num ? 'disabled' : ''" placeholder="请选择" readonly :value="details.sex_desc" />
                            <van-icon name="arrow" v-if="!is_entry" :class="details.card_num ? 'disabled' : ''" />
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">民族</div>
                        </div>
                        <div :class="['right',is_entry ? 'pd-r' : '']" @click="is_entry ? '' : show_nation = true">
                            <!-- <div class="text">{{details.sex_desc}}</div> -->
                            <input :class="is_entry ? 'disabled' : ''" placeholder="请选择" readonly :value="details.nation" />
                            <van-icon name="arrow" v-if="!is_entry" :class="is_entry ? 'disabled' : ''" />
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title">出生年份</div>
                        </div>
                        <div :class="['right',is_entry ? 'pd-r' : '']" @click="is_entry || details.card_num ? '' : show_birthyear = true">
                            <!-- <div class="text">{{details.birthyear}}</div> -->
                            <input :class="is_entry || details.card_num ? 'disabled' : ''" placeholder="请选择" readonly :value="details.birthyear" />
                            <van-icon name="arrow"  v-if="!is_entry" :class="is_entry || details.card_num ? 'disabled' : ''" />
                        </div>
                    </div>
                    
                    <div class="cell">
                        <div class="left">
                            <div class="title">工作年限</div>
                        </div>
                        <div class="right" @click="show_experience = true">
                            <input placeholder="请选择" readonly :value="details.experience_desc" />
                            <van-icon name="arrow" :class="is_entry ? 'disabled' : ''" />
                        </div>
                    </div>

                    <div class="cell">
                        <div class="left">
                            <div class="title">学历</div>
                        </div>
                        <div class="right" @click="show_education = true">
                            <input placeholder="请选择" readonly :value="details.education_desc" />
                            <van-icon name="arrow" :class="is_entry ? 'disabled' : ''" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 其他信息 -->
            <!-- <div class="block">
                <div class="cell" @click="goPhoneNum">
                    <div class="left">
                        <div class="title">手机号码</div>
                    </div>
                    <div class="right">
                        <div>{{details.mobile}}</div>
                        <div>
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </div>
                
            </div> -->
            <!-- 银行卡 -->
            <div class="block">
                <p class="col_1A1A1A font_bold" v-if="bank_flag">银行卡信息
                    <!-- <van-uploader :after-read="getimg" ><span class="col_0ABBB9" @click="go_bank">更换</span></van-uploader> -->
                    <span class="col_0ABBB9" @click="go_bank_two" >更换</span>
                </p>
                <div v-if="!bank_flag">
                    <div class="title_top">
                        <div class="col_1A1A1A font_bold">银行卡信息</div>
                        <!-- <div class="title_top_rt"></div> -->
                        <van-uploader :after-read="getimg">
                            <div class="title_top_rt"></div>
                        </van-uploader>
                        <!-- <div class="title_top_rt"></div> -->
                    </div>
                    <div class="cell" @click="go_bank">
                        <div class="left">
                            <div class="title">添加银行卡</div>
                        </div>
                        <div class="right">
                            <input placeholder="请添加" readonly />
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </div>
                <!-- <div class="border" v-if="!bank_flag">
                    <van-uploader :after-read="getimg">
                        <div class="title_top_rt"></div>
                    </van-uploader>
                </div> -->
                <div v-else>
                    <div class="cell">
                        <div class="left">
                            <div class="title"><span style="color:red" v-if="is_branchbank">*</span>银行名称</div>
                        </div>
                        <div class="right">
                            <!-- <input @blur="onBlur" v-model="details.bank_name" placeholder="请输入"  /> -->
                            <div class="text">{{details.bank_name}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title"><span style="color:red" v-if="is_branchbank">*</span>银行卡号</div>
                        </div>
                        <div class="right">
                            <!-- <input @blur="onBlur" v-model="details.bank_card_num" placeholder="请输入" type="tel" /> -->
                            <div class="text">{{details.bank_card_num}}</div>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="left">
                            <div class="title"><span style="color:red" v-if="is_branchbank">*</span>开户行</div>
                        </div>
                        <div class="right">
                            <!-- <input @blur="onBlur" di v-model="details.branch_bank" placeholder="请输入" /> -->
                            <div class="text">{{details.branch_bank}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="show_experience" round position="bottom">
            <van-picker :default-index="details.experience_key" show-toolbar title="工作年限" :columns="array.experience" value-key="experience_desc" @cancel="show_experience = false" @confirm="pickerSelect($event,'experience')" />
        </van-popup>
        <van-popup v-model="show_education" round position="bottom">
            <van-picker :default-index="details.education_key" show-toolbar title="学历" :columns="array.education" value-key="education_desc" @cancel="show_education = false" @confirm="pickerSelect($event,'education')" />
        </van-popup>
        <van-popup v-model="show_birthyear" round position="bottom">
            <van-picker :default-index="details.birthyear_key" show-toolbar title="出生年份" :columns="arrays" value-key="birthyear_desc" @cancel="show_birthyear = false" @confirm="pickerSelect($event,'birthyear')" />
        </van-popup>
        <van-popup v-model="show_nation" round position="bottom">
            <van-picker :default-index="details.nation_key" show-toolbar title="民族" :columns="arrays_nation" value-key="nation_desc" @cancel="show_nation = false" @confirm="pickerSelect($event,'nation')" />
        </van-popup>
        <van-popup v-model="show_sex" round position="bottom">
            <van-picker :default-index="details.sex_key" show-toolbar title="性别" :columns="arrays_sex_desc" value-key="sex_desc" @cancel="show_sex = false" @confirm="pickerSelect($event,'sex')" />
        </van-popup>
        <!-- 底部 -->
        <div class="footer-bottom"></div>
        <!-- <div class="footer">
            <div @click="save" :style="is_click ? 'background:#aaa' : ''">确认</div>
        </div> -->
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
    </div>
</template>
<script>
import * as imageConversion from 'image-conversion'
import '@/assets/css/index.css'
import { Toast } from 'vant';
export default {
    data() {
        return {
            details: {
                name: ''
            },
            is_entry: false, // 是否在职
            array: {}, // 学历等下拉框
            arrays: [],
            arrays_nation: [
                {
                    nation_desc: '汉',
                    nation_id: '汉'
                },
                {
                    nation_desc: '壮',
                    nation_id: '壮'
                },
                {
                    nation_desc: '满',
                    nation_id: '满'
                },
                {
                    nation_desc: '回',
                    nation_id: '回'
                },
                {
                    nation_desc: '苗',
                    nation_id: '苗'
                },
                {
                    nation_desc: '维吾尔',
                    nation_id: '维吾尔'
                },
                {
                    nation_desc: '土家',
                    nation_id: '土家'
                },
                {
                    nation_desc: '彝',
                    nation_id: '彝'
                },
                {
                    nation_desc: '蒙古',
                    nation_id: '蒙古'
                },
                {
                    nation_desc: '藏',
                    nation_id: '藏'
                },
                {
                    nation_desc: '布依',
                    nation_id: '布依'
                },
                {
                    nation_desc: '侗',
                    nation_id: '侗'
                },
                {
                    nation_desc: '瑶',
                    nation_id: '瑶'
                },
                {
                    nation_desc: '朝鲜',
                    nation_id: '朝鲜'
                },
                {
                    nation_desc: '白',
                    nation_id: '白'
                },
                {
                    nation_desc: '哈尼',
                    nation_id: '哈尼'
                },
                {
                    nation_desc: '哈萨克',
                    nation_id: '哈萨克'
                },
                {
                    nation_desc: '黎',
                    nation_id: '黎'
                },
                {
                    nation_desc: '傣',
                    nation_id: '傣'
                },
                {
                    nation_desc: '畲',
                    nation_id: '畲'
                },
                {
                    nation_desc: '僳僳',
                    nation_id: '僳僳'
                },
                {
                    nation_desc: '仡佬',
                    nation_id: '仡佬'
                },
                {
                    nation_desc: '东乡',
                    nation_id: '东乡'
                },
                {
                    nation_desc: '拉祜',
                    nation_id: '拉祜'
                },
                {
                    nation_desc: '水',
                    nation_id: '水'
                },
                {
                    nation_desc: '佤',
                    nation_id: '佤'
                },
                {
                    nation_desc: '纳西',
                    nation_id: '纳西'
                },
                {
                    nation_desc: '羌',
                    nation_id: '羌'
                },
                {
                    nation_desc: '土',
                    nation_id: '土'
                },
                {
                    nation_desc: '仫佬',
                    nation_id: '仫佬'
                },
                {
                    nation_desc: '锡伯',
                    nation_id: '锡伯'
                },
                {
                    nation_desc: '柯尔克孜',
                    nation_id: '柯尔克孜'
                },
                {
                    nation_desc: '达翰尔',
                    nation_id: '达翰尔'
                },
                {
                    nation_desc: '景颇',
                    nation_id: '景颇'
                },
                {
                    nation_desc: '毛南',
                    nation_id: '毛南'
                },
                {
                    nation_desc: '撒拉',
                    nation_id: '撒拉'
                },
                {
                    nation_desc: '布朗',
                    nation_id: '布朗'
                },
                {
                    nation_desc: '塔吉克',
                    nation_id: '塔吉克'
                },
                {
                    nation_desc: '阿昌',
                    nation_id: '阿昌'
                },
                {
                    nation_desc: '普米',
                    nation_id: '普米'
                },
                {
                    nation_desc: '鄂温克',
                    nation_id: '鄂温克'
                },
                {
                    nation_desc: '怒',
                    nation_id: '怒'
                },
                {
                    nation_desc: '京',
                    nation_id: '京'
                },
                {
                    nation_desc: '基诺',
                    nation_id: '基诺'
                },
                {
                    nation_desc: '德昂',
                    nation_id: '德昂'
                },
                {
                    nation_desc: '保安',
                    nation_id: '保安'
                },
                {
                    nation_desc: '俄罗斯',
                    nation_id: '俄罗斯'
                },
                {
                    nation_desc: '裕固',
                    nation_id: '裕固'
                },
                {
                    nation_desc: '乌孜别克',
                    nation_id: '乌孜别克'
                },
                {
                    nation_desc: '门巴',
                    nation_id: '门巴'
                },
                {
                    nation_desc: '鄂伦春',
                    nation_id: '鄂伦春'
                },
                {
                    nation_desc: '独龙',
                    nation_id: '独龙'
                },
                {
                    nation_desc: '塔塔尔',
                    nation_id: '塔塔尔'
                },
                {
                    nation_desc: '赫哲',
                    nation_id: '赫哲'
                },
                {
                    nation_desc: '高山',
                    nation_id: '高山'
                },
                {
                    nation_desc: '珞巴',
                    nation_id: '珞巴'
                }
            ], //民族
            arrays_sex_desc: [
                {
                    sex_desc: '男',
                    sex_id: '1'
                },
                {
                    sex_desc: '女',
                    sex_id: '2'
                }
            ],//性别
            show_education: false, // 学历
            show_experience: false, // 工作年限
            show_birthyear: false,//出生年份
            show_sex: false,//性别
            show_nation: false,//性别
            bank_flag: false, // 默认没有银行卡
            bd_token: '',
            is_click: false,
            url: '',
            is_branchbank: false, // 是否需要输入开户行
        }
    },
    activated() {
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        var date = new Date();
        let year = Number(date.getFullYear()) - 16;
        let arr = [];
        for (let i = 0; i < 45; i++) {
            arr.push({
                birthyear_desc: year - i,
                birthyear_id: year - i
            })
        }
        this.arrays = arr;
        this.getInfo()
        // if (this.$route.params.details) {
        //     this.details = this.$route.params.details ? JSON.parse(this.$route.params.details) : {}
        // }
        // // 跳转到微信且返回的时候会刷新
        // if (this.$route.params.id_card) {
        //     this.details.name = this.$store.state.idcardList.real_name
        //     this.details.sex_desc = this.$store.state.idcardList.sex
        //     this.details.birthyear = this.$store.state.idcardList.birthday.substring(0, 4)
        //     this.details.card_num = this.$store.state.idcardList.card_num
        // }
        // if (this.details.bank_name || this.details.bank_card_num || this.details.branch_bank) {
        //     this.bank_flag = true
        // } else {
        //     this.bank_flag = false
        // }
        // this.details = { ...this.details }
    },
    created() {
        this.$store.commit("hideTabbar");
        // if (this.$route.params.details) {
        //     this.details = this.$route.params.details ? JSON.parse(this.$route.params.details) : {}
        //     if (this.details.bank_name || this.details.bank_card_num || this.details.branch_bank) {
        //         this.bank_flag = true
        //     } else {
        //         this.bank_flag = false
        //     }
        // }
        // this.getInfo()
        this.getCategoryInfo()
        //this.ISBranchBank()
        this.Request('ai_baidu/access_token', 'get').then(res => {
            this.bd_token = res.data.access_token
        })
    },
    methods: {
        gobanck() {
            this.$router.go(-1);
        },
        goeditname(type) {
            if (this.is_entry) {
                return
            }
            this.$router.push({
                name: 'editnamecard', params: {
                    type: type,
                    jobseeker_name: this.details.name,
                    card_num: this.details.card_num,
                    detaills: this.details
                }
            });
        },
        getInfo() {
            this.Request('client/mine/info', 'get', { team_id: localStorage.getItem('team_id') }).then(res => {
                if (res.status == 0) {
                    this.details = res.data.basic;
                    if (this.details.sex == 1) { // 性别
                        this.details.sex_key = 0
                    }else{
                        this.details.sex_key = 1
                    }
                    if(this.details.nation){ // 民族
                        this.arrays_nation.filter((v,i)=>{
                            if(v.nation_id == this.details.nation){
                                this.details['nation_key'] = i
                            }
                        })
                    }
                    if(this.details.nation){ // 出生年份
                        this.arrays.filter((v,i)=>{
                            if(v.birthyear_id == this.details.birthyear){
                                this.details['birthyear_key'] = i
                            }
                        })
                    } 
                    Object.keys(this.array).forEach(key => {
                        if (key == 'experience' || key == 'education') {
                            this.array[key].filter((v, i) => {
                                if (v[key + '_id'] == this.details[key]) {
                                    this.details[key] = v[key + '_id']
                                    this.details[key + '_desc'] = v[key + '_desc']
                                    this.details[key + '_key'] = i
                                }
                            })
                        }
                    })
                   if(!res.data.basic.sex){
                       this.details.sex_desc = ''
                   }
                    if (res.data.employ_status) {
                        this.is_entry = true
                    } else {
                        this.is_entry = false
                    }
                    if (res.data.basic && (res.data.basic.bank_name || res.data.basic.bank_card_num || res.data.basic.branch_bank)) {
                        this.bank_flag = true
                    } else {
                        this.bank_flag = false
                    }
                }
            })
        },
        //
        go_bank() {
            this.$router.push({
                name: 'bankedit', params: {
                    edit: 2
                }
            });
        },
        go_bank_two() {
            this.$router.push({
                name: 'bankedit', params: {
                    edit: 1,
                    bank_name: this.details.bank_name,
                    bank_card_num: this.details.bank_card_num,
                    branch_bank: this.details.branch_bank,
                }
            });
        },
        getimgidcard(e) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            imageConversion.compressAccurately(e.file, 100).then(res => {
                let img = new window.File([res], 'cs.png', { type: 'image/png' },);
                this.getBase64(img).then(res => {
                    var image = res
                    let params = new FormData()
                    params.append('image', res)
                    params.append('id_card_side', 'front')
                    params.append('access_token', this.bd_token)
                    this.axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded'
                    this.axios.post('https://aip.baidubce.com/rest/2.0/ocr/v1/idcard', params).then(res => {
                        if (res.data.idcard_number_type < 0) {
                            Toast.clear()
                            Toast('请上传正确的身份证图片')
                            return
                        }
                        if (res.data.words_result['出生']) {
                            this.Request('card/img/upload', 'post', { img: this.url.split(',')[1], img_type: 'id_card' }).then(res2 => {
                                this.$store.commit('idcardURL_add', { index: 0, item: res2.data[0] })
                            })
                            if (!!res.data.words_result['姓名'].words == false || !!res.data.words_result['性别'].words == false || !!res.data.words_result['民族'].words == false || !!res.data.words_result['住址'].words == false || !!res.data.words_result['出生'].words == false || !!res.data.words_result['公民身份号码'].words == false) {
                                Toast.clear()
                                Toast('请上传正确的身份证人像面图片')
                                return
                            } else {
                                Toast.clear()
                                Toast.success('上传成功')
                            }
                            var birthday = res.data.words_result['出生'].words
                            // let data = {
                            //     'jobseeker_name': res.data.words_result['姓名'].words,
                            //     'sex': res.data.words_result['性别'].words,
                            //     'nation': res.data.words_result['民族'].words,
                            //     'native_place': res.data.words_result['住址'].words,
                            //     'birthyear': birthday.substring(0, 4) + '-' + birthday.substring(4, 6) + '-' + birthday.substring(6, 8),
                            //     'jobseeker_id_card': res.data.words_result['公民身份号码'].words,
                            //     is_card_true: false
                            // }
                            let datatwo = {
                                'jobseeker_name': res.data.words_result['姓名'].words,
                                'sex': res.data.words_result['性别'].words == '男' ? 1 : 2,
                                'nation': res.data.words_result['民族'].words,
                                'native_place': res.data.words_result['住址'].words,
                                'birthyear': Number(birthday.substring(0, 4)),
                                'jobseeker_id_card': res.data.words_result['公民身份号码'].words
                            }
                            this.url = image;
                            this.Request('client/mine/info', 'post', datatwo, true, false).then(res => {
                                if (res.status == 0) {
                                    this.getInfo()
                                    // this.$router.go(-1)
                                }
                                this.is_click = false
                            })
                            // this.$store.commit('update', data)
                            this.url = image
                            // this.isflag1 = false
                        }

                    })
                    Toast.clear()
                })
            });
        },
        // 开户行是否必填
        ISBranchBank() {
            this.Request('team/config', 'get', {
                team_id: localStorage.getItem('team_id')
            }).then(res => {
                if (res.status == 0 && res.data.branch_bank_required == 1) {
                    this.is_branchbank = true
                }
            })
        },
        goIdCard() {
            this.$router.push({ name: 'IdCard', params: { details: JSON.stringify(this.details) } });
        },
        // 获取学历，工作经验等下拉框
        getCategoryInfo() {
            this.Request('category', 'get', { type: 'all' }).then(res => {
                if (res.status == 0) {
                    this.array = {...this.array,...res.data}
                    this.getInfo()
                }
            })
        },
        goPhoneNum() {
            this.$router.replace({ name: 'PhoneNum', params: { details: JSON.stringify(this.details) } });
        },
        // 解决ios端的键盘弹起来后会把页面顶上去，导致点击其他元素（尤其是弹出框）的效果失效
        onBlur() {
            setTimeout(function () {
                let scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            }, 100);
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        // picker选择
        pickerSelect(value, type) {
            if (type != 'experience' && type != 'education') {
                if (this.is_entry) {
                    Toast("已在职，不能修改")
                    return
                }
            }
            this.details[type] = value[type + '_id']
            this.details[type + '_desc'] = value[type + '_desc']
            this['show_' + type] = false
            this.save()
        },
        save() {
            var data = {}
            if (this.details.experience)
                data.experience = this.details.experience

            if (this.details.education)
                data.education = this.details.education

            if (this.is_branchbank) {
                // 银行名称
                if (!!this.details.bank_name == false) {
                    Toast('请输入银行名称');
                    return
                }
                // 银行卡号
                if (!!this.details.bank_card_num == false) {
                    Toast('请输入银行卡号');
                    return
                }
                if (!!this.details.branch_bank == false) {
                    Toast('请输入开户行');
                    return
                }
            } else {
                // 银行名称
                if (this.details.bank_name) {
                    data.bank_name = this.details.bank_name
                }
                // 银行卡号
                if (this.details.bank_card_num) {
                    data.bank_card_num = this.details.bank_card_num
                }
                data.branch_bank = this.details.branch_bank || ''
            }
            // if(this.is_click) return
            //     this.is_click = true
            // 姓名
            if (this.$store.state.idcardList.real_name) { // 待修改
                data.jobseeker_name = this.$store.state.idcardList.real_name
            }
            // 性别
            if (this.details.sex) {
                data.sex = this.details.sex
            }
            // 生日
            if (this.$store.state.idcardList.birthday || this.details.birthyear) {
                data.birthyear = this.details.birthyear || this.$store.state.idcardList.birthday.substring(0, 4)
            }
            // 民族
            if (this.details.nation) {
                data.nation = this.details.nation
            }
            // 身份证号
            if (this.details.card_num) { // 待修改
                data.jobseeker_id_card = this.details.card_num.toUpperCase()
            }
            this.Request('client/mine/info', 'post', data, true, false).then(res => {
                if (res.status == 0) {
                    // this.$router.go(-1)
                }
                this.is_click = false
            })
        },
        getimg(e) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            this.getBase64(e.file).then(res => {
                this.url = res
                let params = new FormData()
                params.append('image', res)
                params.append('access_token', this.bd_token)
                this.axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded'
                this.axios.post('https://aip.baidubce.com/rest/2.0/ocr/v1/bankcard', params).then(res => {
                    Toast.clear()
                    this.details.bank_name = res.data.result.bank_name
                    this.details.bank_card_num = res.data.result.bank_card_number.replace(/\D/g, '')
                    this.details.branch_bank = ''
                    this.$router.push({
                        name: 'bankedit', params: {
                            edit: 1,
                            bank_name: res.data.result.bank_name,
                            bank_card_num: res.data.result.bank_card_number.replace(/\D/g, '')
                        }
                    });
                    this.details = { ...this.details }
                    this.bank_flag = true;
                    // if(res.data.result.bank_name){
                    //     this.save()
                    // }


                    if (res.data.result.bank_card_type == 0) {
                        Toast('银行卡信息识别失败')
                    }
                }).catch(() => {
                    Toast.clear()
                    this.bank_flag = true
                    this.details.branch_bank = ''
                    this.details.bank_name = ''
                    this.details.bank_card_num = ''
                })

            })
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function () {
                    imgResult = reader.result;
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    resolve(imgResult);
                };
            });
        },
    }
}
</script>
<style scoped>
.pd-r{
    padding-right: 35px;
}
.font_bold {
    font-weight: bold;
}
.content {
    /* padding-top: 90px; */
    text-align: left;
}
.block {
    padding: 10px 30px;
    background: #fff;
    margin-bottom: 20px;
}
.title_top {
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}
.title_top_rt {
    width: 40px;
    height: 34px;
    background: url("../../assets/img/xiangji.png");
    background-size: 100% 100%;
}
.block p {
    margin-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 40px;
}
.block p span {
    color: #4c94f6;
    font-size: 24px;
}
.disabled {
    color: #999;
}
.block .border {
    border: 2px solid #f8f8f8;
    border-radius: 10px;
    height: 228px;
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.block .border > div {
    width: 150px;
    margin: auto;
}
.block .button {
    width: 150px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #4c94f6;
    border-radius: 10px;
    margin: auto;
    color: #fff;
    font-size: 24px;
}
/* input输入框样式 */
.cell {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #f8f8f8;
    padding: 14px 0;
    position: relative;
    height: 60px;
    line-height: 60px;
}
.cell .left {
    display: flex;
    width: 28%;
}
.cell .left .must {
    color: red;
    margin: 6px 10px 0 0;
    width: 10px;
}
/* .cell .left .title {
    min-width: 132px;
    text-align-last: justify;
} */
.cell .right {
    width: 75%;
    display: flex;
    justify-content: flex-end;
}
.cell .right .text {
    color: #333;
    text-align: right;
}
.cell .right .text,
.cell .right input {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    white-space: nowrap;
    text-align: right;
    background: transparent;
}
.block .cell:last-of-type {
    border: 0;
}
.van-icon {
    line-height: inherit;
    margin-left: 10px;
}
</style>